<template>
  <div v-if="notify">
    <div id="notification-box" class="notification-box">
      <a href="#" id="notification-close" @click="closed()" title="close">x</a>

      <div class="notification-data">
        <div class="notification-title">
          <p id="title">
            {{ title }}
          </p>
        </div>

        <div class="notification-notice">
          <p id="subject">{{ body }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMessage } from "firebase/messaging";
export default {
  name: "NotificationBox",

  components: {},

  data() {
    return {
      title: "",
      body: "",
      img: "",
      notify: false,
    };
  },

  methods: {
    receiveMessage() {
      try {
        onMessage(this.$messaging, (payload) => {
          console.log("Message received. ", payload);

          this.title = payload.notification?.title || "";
          this.body = payload.notification?.body || "";
          this.img = payload.notification?.image;

          this.notify = true;
          setTimeout(() => {
            this.notify = false;
          }, 3000);
        });
      } catch (e) {
        console.log(e);
      }
    },
  },

  created() {
    // this.receiveMessage();
  },

  validations: function () {
    return {};
  },

  computed: {},
};
</script>

<style>
.notification-box {
  display: flex;
  position: absolute;
  right: 5px;
  bottom: 10px;
  width: 30%;
  height: 100px;
  background-color: #fff;
  z-index: 999;
}
#notification-close {
  position: absolute;
  color: #777;
  font: 14px;
  right: 5px;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  top: 2px;
  z-index: 10;
  cursor: pointer;
}

.notification-data {
  margin: 0 20px;
}
.notification-data p {
  margin: 10px 0;
}
.notification-notice p {
  font-size: 14px;
  font-weight: 400;
}
.notification-title p {
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
}
.notification-time small {
  font-size: 12px;
}

.notification-wall-number small {
  font-size: 8px;
}
img {
  width: 30px;
}
</style>
