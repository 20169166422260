<template>
  <div id="app">
    <navbar-layout />
    <div class="container content-router pt-6">
      <router-view />
    </div>
    <notification-box />
  </div>
</template>

<script>
import NavbarLayout from "@/layout/NavbarLayout.vue";
import NotificationBox from "@/components/notificationBox.vue";

export default {
  components: {
    NavbarLayout,
    NotificationBox,
  },
  computed: {
    routerParams() {
      return this.$route.params;
    },
  },
};
</script>

<style lang="scss">
@import "./assets/css/global.scss";
@import "./assets/css/variables.scss";

body {
  background-color: $background-color;
}
.content-router {
  background-color: $content-color;
}
</style>
