import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "vue-loading-overlay/dist/vue-loading.css";
import Loading from "vue-loading-overlay";

// import firebaseMessaging from "./libs/firebase";

// Vue.prototype.$messaging = firebaseMessaging;

Vue.config.productionTip = false;
Vue.component("Loading", Loading);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
