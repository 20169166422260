<template>
  <div>
    <div>
      <pdf
        ref="pdf"
        :src="pdfdata"
        v-for="i in numPages"
        :key="i"
        :text="false"
        :page="i"
        style="width: 100%; margin: 0"
      >
      </pdf>
    </div>
  </div>
</template>

<script>
// https://www.npmjs.com/package/pdfvuer
import pdf from "pdfvuer";

export default {
  components: {
    pdf,
  },
  props: {
    menu: String,
  },
  mounted() {
    this.$emit("showLoading", this.showLoading);
    this.getPdf();
  },
  data() {
    return {
      page: 0,
      numPages: undefined,
      src: undefined,
      pdfdata: undefined,
      errors: [],
      scale: "page-width",
      showLoading: true,
    };
  },
  watch: {
    menu() {
      this.showLoading = true;
      this.$emit("showLoading", this.showLoading);
      this.pdfdata = undefined;
      this.numPages = undefined;
      this.getPdf();
    },
  },
  methods: {
    getPdf() {
      this.pdfdata = pdf.createLoadingTask(`/static/pdf/${this.menu}.pdf`);
      this.pdfdata.then((pdf) => {
        this.numPages = pdf.numPages;
        this.showLoading = false;
        this.$emit("showLoading", this.showLoading);
      });
    },
  },
  beforeDestroy() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
