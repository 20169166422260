<template>
  <div>
    <pdf-viewer :menu="pdfFile" @showLoading="showLoading" />
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </div>
</template>

<script>
// @ is an alias to /src
import PdfViewer from "@/components/pdfViewer2.vue";

export default {
  components: {
    PdfViewer,
  },
  data() {
    return {
      isLoading: false,
      // pdfFile: "web",
      restaurants: ["web", "carolinas", "carolina", "primavera", "explanada"],
    };
  },
  computed: {
    pdfFile() {
      let restaurant = "web";
      if (this.restaurants.includes(this.$route.params.restaurant)) {
        restaurant = this.$route.params.restaurant;

        // Solve error on qr code
        if (restaurant === "carolina") {
          restaurant = "carolinas";
        }

        if (this.$route.params.restaurant !== "web") {
          // Spreaad purchase
          window._mtm.push({
            event: "event.menu",
          });
        }
      }

      return restaurant;
    },
  },
  mounted() {
    window._mtm.push({ restaurante: this.pdfFile });
  },
  methods: {
    showLoading(ableToShow) {
      this.isLoading = ableToShow;
    },
  },
};
</script>
