<template>
  <nav
    class="navbar is-fixed-top"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="container">
      <div class="navbar-brand">
        <div class="navbar-item">
          <img src="@/static/logo.png" alt="logo los cortes" />
        </div>
        <div class="navbar-end" v-show="isMobile">
          <a
            class="navbar-item"
            target="_blank"
            id="instagram"
            href="https://www.instagram.com/loscortesrestaurante/"
          >
            <span class="icon">
              <i class="fab fa-instagram"></i>
            </span>
          </a>
          <a
            class="navbar-item"
            target="_blank"
            id="facebook"
            href="https://www.facebook.com/loscortesrestaurante"
          >
            <span class="icon">
              <i class="fab fa-facebook"></i>
            </span>
          </a>
          <a
            class="navbar-item"
            target="_blank"
            id="whatsapp"
            href="https://wa.me/526674138519"
          >
            <span class="icon">
              <i class="fab fa-whatsapp"></i>
            </span>
          </a>
          <a
            class="navbar-item"
            target="_blank"
            id="tiktok"
            href="https://www.tiktok.com/@loscortesrestaurante"
          >
            <span class="icon">
              <i class="fab fa-tiktok"></i>
            </span>
          </a>
        </div>
        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          v-on:click="showNav = !showNav"
          v-bind:class="{ 'is-active': showNav }"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        class="navbar-menu"
        v-bind:class="{ 'is-active': showNav }"
        id="navbarBasicExample"
      >
        <div class="navbar-start">
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link"> Menú </a>
            <div class="navbar-dropdown">
              <a
                class="navbar-item"
                :href="$router.resolve({ name: 'Home' }).href"
              >
                General
              </a>
              <a
                class="navbar-item"
                v-for="restaurant in restaurants"
                :key="restaurant.url"
                :href="
                  $router.resolve({
                    name: 'Menu',
                    params: { restaurant: restaurant.url },
                  }).href
                "
              >
                {{ restaurant.name }}
              </a>
            </div>
          </div>
          <!--
          <router-link class="navbar-item" :to="{ name: 'Home' }">
            Menú
          </router-link>
          -->
          <router-link class="navbar-item" :to="{ name: 'sucursales' }">
            Sucursales
          </router-link>
          <router-link class="navbar-item" :to="{ name: 'promo' }">
            Promociones
          </router-link>
        </div>
      </div>

      <div class="navbar-end" v-show="!isMobile">
        <a
          class="navbar-item"
          target="_blank"
          id="instagram"
          href="https://www.instagram.com/loscortesrestaurante/"
        >
          <span class="icon">
            <i class="fab fa-instagram"></i>
          </span>
        </a>
        <a
          class="navbar-item"
          target="_blank"
          id="facebook"
          href="https://www.facebook.com/loscortesrestaurante"
        >
          <span class="icon">
            <i class="fab fa-facebook"></i>
          </span>
        </a>
        <a
          class="navbar-item"
          target="_blank"
          id="whatsapp"
          href="https://wa.me/526674138519"
        >
          <span class="icon">
            <i class="fab fa-whatsapp"></i>
          </span>
        </a>
        <a
          class="navbar-item"
          target="_blank"
          id="tiktok"
          href="https://www.tiktok.com/@loscortesrestaurante"
        >
          <span class="icon">
            <i class="fab fa-tiktok"></i>
          </span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      showNav: false,
      window: {
        width: 0,
      },
      restaurants: [
        {
          name: "Sucursal Primavera",
          url: "primavera",
        },
        {
          name: "Sucursal Explanada",
          url: "explanada",
        },
        {
          name: "Sucursal Plaza Carolinas",
          url: "carolinas",
        },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.window.width < 769;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.navbar {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  .navbar-brand {
    align-items: center;
    .navbar-burger {
      color: $navbar-item-color !important;
      &:hover {
        color: $navbar-item-hover-color !important;
      }
    }

    .navbar-item img {
      width: 4rem;
      max-height: 4rem;
    }
  }
  .navbar-menu {
    .navbar-start {
      a.navbar-item {
        color: $navbar-item-color !important;
        &:hover {
          color: $navbar-item-hover-color !important;
        }
      }
      .navbar-item.has-dropdown.is-hoverable {
        .navbar-dropdown {
          .navbar-item:hover {
            color: $navbar-item-hover-color !important;
            background-color: $navbar-item-hover-background-color;
          }
        }
      }
    }
  }

  .navbar-end {
    justify-content: flex-end;
    margin-left: auto;
    align-items: center;
    display: flex;
    @media screen and (max-width: 1024px) {
      padding: 0.5rem 0.75rem;
    }

    .navbar-item {
      padding: 0.5rem 1.1rem;
      .icon {
        font-size: 2.5em;
      }
    }
    .navbar-item:hover {
      background-color: transparent;
      opacity: 0.7;
    }

    #whatsapp {
      color: #4fce5d;
    }
    #instagram {
      color: #e95950;
    }
    #facebook {
      color: #1877f2;
    }
    #tiktok {
      color: #fff;
    }
  }
  .navbar {
    align-items: stretch;
    display: flex;
    min-height: 3.25rem;
    width: 100%;
  }
}
</style>
